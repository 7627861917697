import React from 'react'
import Layout from "../../components/Layout";
import tnsConvenios from "../../img/proyecto-tns.png";
import girha from "../../img/proyecto-girha.png";
import rebore from "../../img/rebote-game.png";
import commerce from "../../img/plant-store.png";
import crisweb from "../../img/crisweb-project.png";
import landing from "../../img/landing-project.png";
import lastcargo from "../../img/lastcargo.png";
import patent from "../../img/patent.png";
import currency from "../../img/currency.png";


const ProjectsPage = () => (
    <Layout>
        <section className="section" id="portfolio">
            <div className="container">
                <div className="section-heading">
                    <h3 className="title is-2">Proyectos</h3>
                    <h4 className="subtitle is-5">Ultimos Trabajos</h4>
                </div>
                <br />
                <div className="container portfolio-container">
                    <div className="columns">
                        <div className="column is-4">
                            {/* admin */}
                            <div className="card">
                                <header className="card-header">
                                    <p className="card-header-title">
                                        <span>Landing Page T.N.S convenios </span>
                                        <span className="is-pulled-right">
                        <a href="https://github.com/jgthms/bulma/releases/tag/0.7.2">
                          <span className="tag is-default">v0.0.1</span>
                        </a>
                                            <span className="tag is-warning">WEB</span>
                      </span>
                                    </p>
                                </header>
                                <div className="card-content">
                                    <figure className="image">
                                        <img
                                            className="imagen"
                                            src={tnsConvenios}
                                            alt="T.N.S Convenios"
                                        />
                                    </figure>
                                    <span>Proyecto T.N.S Agencia Asesora De
Convenios, firma de contratos
empresariales con empresas y
aseguradoras en la ciudad de Quito.
Como fase inicial Branding de la marca con diseños de
logo, slogan, tipografía y colores. Enfocados en el negocio con la utilización de palabras claves.</span>
                                </div>
                                <footer className="card-footer">
                                    <a href="https://www.tnsconvenios.com" className="card-footer-item">Previa Sitio</a>
                                </footer>
                            </div>
                        </div>
                        <div className="column is-4">
                            {/* admin */}
                            <div className="card">
                                <header className="card-header">
                                    <p className="card-header-title">
                                        <span>Landing Page Lastcargo </span>
                                        <span className="is-pulled-right">
                        <a href="https://github.com/jgthms/bulma/releases/tag/0.7.2">
                          <span className="tag is-default">v0.0.1</span>
                        </a>
                                            {/*<span className="tag is-default">Desktop</span>*/}
                                            <span className="tag is-warning">WEB</span>
                      </span>
                                    </p>
                                </header>
                                <div className="card-content">
                                    <figure className="image">
                                        <img
                                            className="imagen"
                                            src={lastcargo}
                                            alt="Landing page lastcargo"
                                        />
                                    </figure>
                                    <span>
                                        Desarrollo de un sitio web minimalista con diseño responsive, rendimiento y SEO optimizado con las secciones de Inicio, Quienes Somos y Contacto, así como la sección del Login. Puedes revisar una vista previa del sitio con la arquitectura JAMstack la moderna manera de desarrollar sitios web escalables y seguros que arquitecturas tradicionales.
                                    </span>
                                </div>
                                <footer className="card-footer">
                                    <a href="https://lastcargo.gatsbyjs.io/" className="card-footer-item">Previa Sitio</a>
                                    {/*<a href="templates/admin.html" className="card-footer-item">Preview</a>*/}
                                    {/*<a href="https://github.com/BulmaTemplates/bulma-templates/blob/master/templates/admin.html" className="card-footer-item">Source*/}
                                    {/*    Code</a>*/}
                                </footer>
                            </div>
                        </div>
                        <div className="column is-4">
                            {/* admin */}
                            <div className="card">
                                <header className="card-header">
                                    <p className="card-header-title">
                                        <span>Sitio web Patent</span>
                                        <span className="is-pulled-right">
                        <a href="https://github.com/jgthms/bulma/releases/tag/0.7.2">
                          <span className="tag is-default">v0.0.1</span>
                        </a>
                                            {/*<span className="tag is-default">Desktop</span>*/}
                                            <span className="tag is-warning">WEB</span>
                      </span>
                                    </p>
                                </header>
                                <div className="card-content">
                                    <figure className="image">
                                        <img
                                            className="imagen"
                                            src={patent}
                                            alt="Juego rebote pelota"
                                        />
                                    </figure>
                                    <span>
                                    Desarrollo de sitio web responsive, conectando wordpress como backend con las secciones de Home, Features, Team, Blog. Puedes revisar una vista previa del sitio con la arquitectura JAMstack la moderna manera de desarrollar sitios web escalables y seguros que arquitecturas tradicionales.
                                    </span>
                                </div>
                                <footer className="card-footer">
                                    <a href="https://patent.gatsbyjs.io/" className="card-footer-item">Previa Juego</a>
                                </footer>
                            </div>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-4">
                            {/* admin */}
                            <div className="card">
                                <header className="card-header">
                                    <p className="card-header-title">
                                        <span>Tienda en Linea Ecommerce </span>
                                        <span className="is-pulled-right">
                        <a href="https://github.com/jgthms/bulma/releases/tag/0.7.2">
                          <span className="tag is-default">v0.0.1</span>
                        </a>
                                            <span className="tag is-warning">WEB</span>
                      </span>
                                    </p>
                                </header>
                                <div className="card-content">
                                    <figure className="image">
                                        <img
                                            className="imagen"
                                            src={commerce}
                                            alt="T.N.S Convenios"
                                        />
                                    </figure>
                                    <span>Esta es una prueba de cómo se puede desarrollar un Ecommerce optimizado con SEO y un buen rendimiento las tecnologías utilizadas son Gatsby para el lado del cliente, Snipcart para la parte de la tienda online y para la creación de contenido markdowns como un CMS sin cabeza. A diferencia de desarrollar un sitio en wordpress este tiene sus ventajas ya que utilizamos tecnologías Javascript de lado del cliente que permiten optimizar un sitio web o una aplicación web.  </span>
                                </div>
                                <footer className="card-footer">
                                    <a href="https://good-oregano-dddde.netlify.app" className="card-footer-item">Previa Sitio</a>
                                </footer>
                            </div>
                        </div>
                        <div className="column is-4">
                            {/* admin */}
                            <div className="card">
                                <header className="card-header">
                                    <p className="card-header-title">
                                        <span>Landing Page Startup </span>
                                        <span className="is-pulled-right">
                        <a href="https://github.com/jgthms/bulma/releases/tag/0.7.2">
                          <span className="tag is-default">v0.0.1</span>
                        </a>
                                            <span className="tag is-warning">WEB</span>
                      </span>
                                    </p>
                                </header>
                                <div className="card-content">
                                    <figure className="image">
                                        <img
                                            className="imagen"
                                            src={landing}
                                            alt="T.N.S Convenios"
                                        />
                                    </figure>
                                    <span>Desarrolla una landing page que te genere conversiones con métricas de SEO optimizadas, Coloca tus CTA(botones de llamado a la acción) que te permitan llegar a más personas así mismo como obtener potenciales clientes. Una página web de aterrizaje o landing page te permite resumir tus servicios y productos en una sola página con el objetivo de entregar la información necesaria para poder contactar con un posible cliente este template es 100% con principios de diseño de usabilidad y funcionalidades como el dark mode o modo oscuro. Explora este sitio y no dude en obtener asesoría totalmente gratis.  </span>
                                </div>
                                <footer className="card-footer">
                                    <a href="https://crisweb-landing-seven.vercel.app/" className="card-footer-item">Previa Sitio</a>
                                </footer>
                            </div>
                        </div>
                        <div className="column is-4">
                            {/* admin */}
                            <div className="card">
                                <header className="card-header">
                                    <p className="card-header-title">
                                        <span>Crisweb Página Oficial </span>
                                        <span className="is-pulled-right">
                        <a href="https://github.com/jgthms/bulma/releases/tag/0.7.2">
                          <span className="tag is-default">v2.0.1</span>
                        </a>
                                            <span className="tag is-warning">WEB</span>
                      </span>
                                    </p>
                                </header>
                                <div className="card-content">
                                    <figure className="image">
                                        <img
                                            className="imagen"
                                            src={crisweb}
                                            alt="T.N.S Convenios"
                                        />
                                    </figure>
                                    <span>Este proyecto ya tiene el nuevo enfoque para poder crear sitio web estáticos las tecnologías utilizadas son gatsby y para el CMS Netlify CMS la elaboración de este sitio web con varias páginas así como la actualización constante del blog. Como características principales; contenido optimizado con SEO aplicando principios de usabilidad que permiten al usuario encontrar de manera rápido, alto rendimiento el tiempo de carga del sitio web esta optimizado así mismo como el código javascript y las imágenes que se utilizan para la pagina de inicio. El análisis de sitio web de métricas esta con Google Analytics, Yandex y Hotjar nos permiten un feedback para la mejorar del sitio web.  </span>
                                </div>
                                <footer className="card-footer">
                                    <a href="https://crisweb.me" className="card-footer-item">Previa Sitio</a>
                                </footer>
                            </div>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-4">
                            {/* admin */}
                            <div className="card">
                                <header className="card-header">
                                    <p className="card-header-title">
                                        <span>Currency Management Angular </span>
                                        <span className="is-pulled-right">
                        <a href="https://github.com/jgthms/bulma/releases/tag/0.7.2">
                          <span className="tag is-default">v0.0.1</span>
                        </a>
                                            <span className="tag is-warning">WEB</span>
                      </span>
                                    </p>
                                </header>
                                <div className="card-content">
                                    <figure className="image">
                                        <img
                                            className="imagen"
                                            src={currency}
                                            alt="T.N.S Convenios"
                                        />
                                    </figure>
                                    <span>
                                       Create a currency manager module in Angular 9+, where the travel agency can set up currency formats depending on the market needs. As stated in the previous paragraph
                                          </span>
                                </div>
                                <footer className="card-footer">
                                    <a href="https://em-angular-exercise.vercel.app/currency" className="card-footer-item">Previa Sitio</a>
                                </footer>
                            </div>
                        </div>
                        <div className="column is-4">
                            {/* admin */}
                            <div className="card">
                                <header className="card-header">
                                    <p className="card-header-title">
                                        <span>Juego Serio Girha </span>
                                        <span className="is-pulled-right">
                        <a href="https://github.com/jgthms/bulma/releases/tag/0.7.2">
                          <span className="tag is-default">v0.0.1</span>
                        </a>
                                            {/*<span className="tag is-default">Desktop</span>*/}
                                            <span className="tag is-success">ESCRITORIO</span>
                      </span>
                                    </p>
                                </header>
                                <div className="card-content">
                                    <figure className="image">
                                        <img
                                            className="imagen"
                                            src={girha}
                                            alt="Juego Serio Girha"
                                        />
                                    </figure>
                                    <span>
                                        GIRHA (Gestión Integral del
Recurso Hídrico y Ambiental)
es un juego serio de escritorio
creado con el objetivo de
promover de una manera
atractiva y divertida el uso
adecuado de los recursos hídricos de la cuenca del Río San Pedro
(ECUADOR), mediante la toma de decisiones, comunicación y trabajo
en equipo, con un diseño amigable y fácil de usar.
                                    </span>
                                </div>
                                <footer className="card-footer">
                                    {/*<a href="templates/admin.html" className="card-footer-item">Preview</a>*/}
                                    {/*<a href="https://github.com/BulmaTemplates/bulma-templates/blob/master/templates/admin.html" className="card-footer-item">Source*/}
                                    {/*    Code</a>*/}
                                </footer>
                            </div>
                        </div>
                        <div className="column is-4">
                            {/* admin */}
                            <div className="card">
                                <header className="card-header">
                                    <p className="card-header-title">
                                        <span>Juego En linea </span>
                                        <span className="is-pulled-right">
                        <a href="https://github.com/jgthms/bulma/releases/tag/0.7.2">
                          <span className="tag is-default">v0.0.1</span>
                        </a>
                                            {/*<span className="tag is-default">Desktop</span>*/}
                                            <span className="tag is-warning">WEB</span>
                      </span>
                                    </p>
                                </header>
                                <div className="card-content">
                                    <figure className="image">
                                        <img
                                            className="imagen"
                                            src={rebore}
                                            alt="Juego rebote pelota"
                                        />
                                    </figure>
                                    <span>
                                      Juego desarrollado por la Agencia Crisweb con el objetivo de probar las diferentes tecnologías que permiten el desarrollo de juegos en línea dentro frameworks como angularjs, ionic, react entre otros utilizando phaser. Para jugar se debe cambiar el color del bloque mostrado en la parte inferior de acuerdo al color mostrado en la pelota que salta en cada uno de los bloques el comando a utilizar es el click izquierdo del mouse. Pruébalo y mira lo simple y entretenido de los juegos.
                                    </span>
                                </div>
                                <footer className="card-footer">
                                    <a href="https://rebote-game.vercel.app/" className="card-footer-item">Previa Juego</a>
                                </footer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
)

export default ProjectsPage
